<nav class="head-nav d-flex">
    <h2>{{ "companyOffers.All Offers and Discounts" | translate }}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="/admin/profile">{{ "general.Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item">{{ "companyOffers.All Offers and Discounts" | translate }}</li>
    </ol>
</nav>

<div class="button-group-custom">
    <mat-button-toggle-group [(ngModel)]="OffeerStatus" (change)="typeFilter($event)">
        <mat-button-toggle *ngFor="let item of typesListAll" [value]="item.id">
            <span>{{item.name | translate}}</span>
            <span class="count" *ngIf="!dataLoading">
                <ng-container *ngIf="item.id == 'not_ended_yet'">{{count_not_ended}}</ng-container>
                <ng-container *ngIf="item.id == 'ended'">{{count_ended}}</ng-container>
            </span>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>

<div class="page">
    <div class="page-filters">
        <div class="sort-item">
            <span class="count-page">
                <span>{{pageCount}}</span> {{ "offers.Offers and discounts" | translate }}
            </span>

            <span class="sort-selection">
                <label for="">{{ 'general.Sorted by' | translate }}:</label>
                <select class="form-control" (change)="getAll(false);"
                    [(ngModel)]="currentSortSelection" (ngModelChange)="OnSelectSortType()">
                    <option *ngFor="let sortItem of sortItems" [value]="sortItem.value">{{sortItem.name | translate}}</option>
                </select>
            </span>
        </div>

        <div class="filters-container">
            <div class="search-input">
                <input *ngIf="showSearch" autocomplete="off" autofocus maxlength="20" [(ngModel)]="filterModel.search"
                    (keydown.enter)="makeSearch()">
                <button mat-icon-button *ngIf="!showSearch" (click)="makeSearch()">
                    <img src="./assets/images/icons/search.svg">
                </button>
                <button *ngIf="showSearch" mat-icon-button (click)="resetSearch(); showSearch = false;">
                    <img class="closesvg" src="./assets/images/icons/ic_close.svg">
                </button>
            </div>

            <button (click)="showFilter = !showFilter" [ngClass]='{"active": showFilter}'
                class="btn dropdown-custom font-14">
                <img src="./assets/images/icons/filter.svg">
                <span>{{"general.Filter" | translate}}</span>
                <svg width="8" height="5.001" viewBox="0 0 8 5.001">
                    <path id="menu-icon" d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
                        transform="translate(4.5 9.316)" fill="#6a6a6a" opacity="0.8" />
                </svg>
            </button>
        </div>

        <div class="filter-section" [hidden]="!showFilter">
            <h4 class="title">{{"general.Filter by" | translate}}</h4>
            <div class="row" [formGroup]="filterFormGroup">
                <div class="col-lg-3 mb-3" *ngIf="_authService.currentUserValue?.company_id">
                    <div class="ng-select-field">
                        <ng-select ngSelectMat appearance="fill" formControlName="department_id"
                            [items]="departmentsList" [virtualScroll]="true" [loading]="departmentsLoading"
                            bindLabel="department_name" bindValue="id"
                            [placeholder]='"employees.The Department" | translate'
                            [notFoundText]='"general.No Data Found" | translate'
                            [loadingText]='"forms.Loading..." | translate' (search)="Department_dropSearch($event)"
                            (clear)="Department_dropSearch($event)" (blur)="Department_dropSearch($event)"
                            (scroll)="onDepartmentsScroll($event)" (scrollToEnd)="onDepartmentsScrollToEnd()"
                            dropdownPosition="bottom" [clearable]="false">
                            <!-- <ng-template ng-header-tmp *ngIf="departmentsList?.length && !departmentsLoading">
                                <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                    {{departmentsList.length}} {{'forms.of' | translate}} {{departmentsCount}}</small>
                            </ng-template> -->
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div title="{{item.department_name}}">{{item.department_name}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>

                <div class="col-lg-3 mb-3" *ngIf="!_authService.currentUserValue?.company_id">
                    <div class="ng-select-field">
                        <ng-select ngSelectMat appearance="fill" formControlName="city"
                            [items]="CitesList" [virtualScroll]="true" [loading]="citiesLoading" bindLabel="name"
                            bindValue="id" [placeholder]='"companies.The City" | translate'
                            [notFoundText]='"general.No Data Found" | translate'
                            [loadingText]='"forms.Loading..." | translate' (search)="dropSearch($event)"
                            (clear)="OnClear()" dropdownPosition="bottom" [clearable]="false">
                            <!-- <ng-template ng-header-tmp *ngIf="CitesList?.length && !citiesLoading">
                                <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                    {{CitesList.length}} {{'forms.of' | translate}} {{citiesCount}}</small>
                            </ng-template> -->
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div title="{{item.name}}">{{item.name}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-3 mb-3" *ngIf="!_authService.currentUserValue?.company_id">
                    <div class="ng-select-field">
                        <ng-select ngSelectMat appearance="fill" formControlName="vendor"
                            [items]="vendorsList" [virtualScroll]="true" [loading]="vendorsLoading" bindLabel="name"
                            bindValue="id" [placeholder]='"vendors.Vendors" | translate'
                            [notFoundText]='"general.No Data Found" | translate' dropdownPosition="bottom"
                            (scroll)="onVendorsScroll($event)" (scrollToEnd)="onVendorsScrollToEnd()"
                            (change)="getAllBranches()" [searchable]="true" [clearable]="false">
                            <!-- <ng-template ng-header-tmp *ngIf="vendorsList?.length && !vendorsLoading">
                                <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                    {{vendorsList.length}} {{'forms.of' | translate}} {{vendorsCount}}</small>
                            </ng-template> -->
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div title="{{item.name}}">{{item.name}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-3 mb-3" *ngIf="!_authService.currentUserValue?.company_id">
                    <div class="ng-select-field">
                        <ng-select ngSelectMat appearance="fill" formControlName="branch"
                            [items]="branchesOfVendor" [virtualScroll]="true" [loading]="branchesOfVendorLoading"
                            bindLabel="name" bindValue="id" [placeholder]='"vendorProfile.Vendors Branches" | translate'
                            [notFoundText]='"general.No Data Found" | translate' dropdownPosition="bottom"
                            [searchable]="true" [clearable]="false">
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div title="{{item.name}}">{{item.name}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-3 mb-3">
                    <div class="ng-select-field">
                        <ng-select ngSelectMat appearance="fill" formControlName="offerType"
                            [items]="offerTypes" [virtualScroll]="true" [loading]="offerTypesLoading" bindLabel="name"
                            bindValue="id" [placeholder]='"offers.Choose Offer Type" | translate'
                            [notFoundText]='"general.No Data Found" | translate' dropdownPosition="bottom"
                            [searchable]="true" [clearable]="false">
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div title="{{item.name}}">{{item.name}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <!-- <div class="col-lg-3 mb-3">
                    <div class="ng-select-field">
                        <ng-select ngSelectMat appearance="fill" formControlName="type" [items]="types"
                            [virtualScroll]="true" bindLabel="name" bindValue="id"
                            [placeholder]='"offers.Select Type" | translate'
                            [notFoundText]='"general.No Data Found" | translate' dropdownPosition="bottom"
                            [searchable]="true" [clearable]="false">
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div title="{{item.name}}">{{item.name}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div> -->
            </div>

            <div class="btn-container">
                <button mat-stroked-button class="btn-primary" (click)="makeFilter()">{{"general.Apply Filter" |
                    translate}}</button>
                <button mat-stroked-button (click)="clearFilter()">{{"general.Clear Filter" | translate}}</button>
            </div>
        </div>
    </div>



    <!-- <ng-container *ngIf='!dataLoading'> -->
    <div *ngIf="offersList && offersList?.length;">
        <div class="cards-list offer-list">
            <div class="row">
                <div class="col-xl-6 col-12" *ngFor="let item of offersList">
                    <div class="card">
                        <div class="card-head">

                            <ng-container *ngIf="item.offer_type.code == 'percentage'">
                                <!-- if cash percentageIcon img -->
                                <img src="./assets/images/offers/percentageIcon.svg" alt="">
                                <p>
                                    {{'offers.discount' | translate }} <span>{{item.amount}}%</span>
                                </p>
                            </ng-container>

                            <ng-container *ngIf="item.offer_type.code == 'fixed_amount'">
                                <!-- if cash back CashIcon img -->
                                <img src="./assets/images/offers/cashIcon.svg" alt="">
                                <p>
                                    {{'offers.discount' | translate }}
                                    <span>{{item.amount}} </span> {{item.currency}}
                                    <ng-container *ngIf="item.buy">
                                        {{'offers.when pay more than' | translate }}
                                        <span>{{item.buy}} </span> {{item.currency}}
                                    </ng-container>
                                </p>
                            </ng-container>

                            <ng-container *ngIf="item.offer_type.code == 'cashback'">
                                <!-- if discount Cash discountCashIcon img -->
                                <img src="./assets/images/offers/discountCashIcon.svg" alt="">
                                <p>
                                    {{ 'offers.Cashback' | translate }}
                                    <span>{{item.get}} </span> {{item.currency}}
                                    <ng-container *ngIf="item.buy">
                                        {{ 'offers.on Purchases of more than' | translate }}
                                        <span>{{item.buy}} </span> {{item.currency}}
                                    </ng-container>
                                </p>
                            </ng-container>

                            <ng-container *ngIf="item.offer_type.code == 'gift'">
                                <!-- if free discount FreeIcon img -->
                                <img src="./assets/images/offers/FreeIcon.svg" alt="">
                                <p>
                                    <span>{{item.get}}</span>
                                    {{'offers.free product when on purchase' | translate}}
                                    <span>{{item.buy}}</span>
                                    {{'offers.products' | translate}}
                                </p>
                            </ng-container>

                            <div *ngIf="item.unified_code == 1">
                                <div class="status-badge light-green">{{'vendorOffers.Unified Code' | translate }}</div>
                            </div>
                        </div>

                        <div class="card-head-content">
                            <h4>
                                <a [class.disabled]="!Permissions?.can_show"
                                    [routerLink]="['/admin/company-offers/company-offer-details',item.id]">
                                    {{item.name}}
                                </a>
                            </h4>
                            <h2>{{item.description}}</h2>
                            <!-- <p><span>{{ 'employeeProfile.Creation Date' | translate }}:</span>
                                {{item.created_at | date :'d MMMM y - h:mm a' }}</p> -->
                            <p *ngIf="item.show_offer_source && item.is_request"> {{ 'offers.The offer was added at the request of the company' | translate }}</p>
                        </div>

                        <div class="info-head">
                            <div class="name-with-image">
                                <div class="image-square">
                                    <img *ngIf="item.vendor.logo" [src]="item.vendor.logo"
                                        (error)='item.vendor.logo = ""'>
                                    <span *ngIf="!item.vendor.logo">{{ item.vendor.name | shortName }}</span>
                                </div>
                                <div class="name">
                                    <h5 class="font-18 fontRegular text-color mb-2">
                                        {{item.vendor.name_ar}}
                                    </h5>
                                    <h5 class="font-18 fontRegular text-color">
                                        {{item.vendor.name_en}}
                                    </h5>
                                </div>
                            </div>

                            <p *ngIf="item.vendor.for_all_branches == 0">
                                <span>{{item.vendor.branches_count}}</span> {{'offers.branches' | translate}}
                            </p>
                            <p *ngIf="item.vendor.for_all_branches == 1">
                                {{'offers.all branches' | translate}}
                            </p>
                        </div>

                        <div class="card-more-details">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="dates" *ngIf="item.start_at || item.end_at">
                                    <p> {{item.start_at | date :'d MMMM y'}}
                                        <ng-container *ngIf="item.end_at">
                                            <span>{{ 'offers.until' | translate }}</span>
                                            {{item.end_at | date :'d MMMM y'}}
                                        </ng-container>
                                    </p>
                                </div>
                                <div class="rate">
                                    <div class="d-flex justify-content-center">
                                        <p class="mb-0">{{item.rate || '0.0'}}</p>
                                        <ng-container *ngIf="item?.rate; else dimmedStar">
                                            <img src="./assets/images/icons/star-filled.svg" alt="" class="mx-1">
                                        </ng-container>
                                        <ng-template #dimmedStar>
                                            <img src="./assets/images/icons/star-gray.svg" alt="" class="mx-1">
                                        </ng-template>
                                    </div>
                                    <span class="mb-0">{{'offers.Offer Rate' | translate}}</span>
                                </div>
                            </div>

                            <div class="bottom-details">
                                <div>
                                    <h2>{{item.offers_used_and_not_applied_count}}</h2>
                                    <p>{{ 'companyOffers.Employees who redeemed from the offer (Not Claimed Yet)' |
                                        translate}}</p>
                                </div>
                                <div>
                                    <h2>{{item.offers_used_and_applied_count}}</h2>
                                    <p>{{ 'companyOffers.Employees redeemed the offer inside the store' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!offersList?.length && !dataLoading">
        <empty-state [title]='"general.No Data Found" | translate'></empty-state>
    </div>
    <div *ngIf='dataLoading' class="dataLoading py-5">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <div class="row justify-content-center" *ngIf="offersList.length < pageCount && !dataLoading">
        <a class="btn-light" mat-stroked-button (click)="loadMoreOffers()">
            <span>{{"offers.View More" | translate}}</span>
        </a>
    </div>

    <!-- </ng-container> -->
</div>